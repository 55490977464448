export const missionValues = {
  title: "Mission",
  text:
    "The Tip Tools Daycare's mission is to create an environment that promotes health, safety, and the child’s development in its entirety. \n" +
    "Tip Tools Daycare emphasizes respect and support among children and their families. Helping children to develop social-emotional skills, building self-esteem, and encouraging activities to explore children’s physical abilities, is a crucial part of our mission.",
};

export const visionValues = {
  title: "Vision",
  text: "Our childcare is a place where children thrive and grow, where their well-being is our top priority, and where they are surrounded by love and nurturing relationships. We provide a safe and supportive environment that promotes physical, emotional, and social well-being. We offer a range of stimulating and engaging activities that promote learning, and encourage curiosity, exploration, and creativity.",
};

export const valuesValues = {
  title: "Values",
  text: `
  We believe in mutual <b>respect</b> in all aspects of the human being. By teaching respect to children, we can help them develop into empathetic behaviour, and create a compassionate and understanding community.<br>
  <br>
  We approach sense of <b>community</b> involving our children, parents, caregivers, and neighbours in our activities and events. By working together and supporting one another, we can create a stronger community that fosters a sense of belonging.<br>
  <br>
  We are committed to providing <b>freedom of exploration</b>. By providing opportunities for children to explore and create in various forms, they can unlock new ways of <b>thinking</b> and <b>problem-solving</b>.<br>
  <br>
  We strive <b>curiosity</b> by provoking questions in the children’s daily experiences. By embracing and encouraging curiosity, children can elaborate questions, seek answers, and try new things that will help them expand their world.<br>`
};